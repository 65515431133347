import { Action, createReducer, on } from '@ngrx/store';

import { AUTH_DATA_ACTIONS } from './auth.actions';
import { AuthDataState, INITIAL_AUTH_DATA_STATE } from './auth.state';

const authDataActionReducer = createReducer(
  INITIAL_AUTH_DATA_STATE,
  on(
    AUTH_DATA_ACTIONS.LoginSuccess,
    (state, { isAuthenticated, token, refreshToken, tokenExpiration, refreshTokenExpiration, userData }) => ({
      ...state,
      isAuthenticated,
      token,
      refreshToken,
      tokenExpiration,
      refreshTokenExpiration,
      userData,
    }),
  ),

  on(
    AUTH_DATA_ACTIONS.LogoutSuccess,
    (state, { token, refreshToken, tokenExpiration, refreshTokenExpiration, isAuthenticated, userData }) => ({
      ...state,
      isAuthenticated,
      token,
      refreshToken,
      tokenExpiration,
      refreshTokenExpiration,
      userData,
    }),
  ),

  on(
    AUTH_DATA_ACTIONS.HydrateTokensSuccess,
    (state, { token, refreshToken, tokenExpiration, refreshTokenExpiration, isAuthenticated, userData }) => ({
      ...state,
      token,
      refreshToken,
      tokenExpiration,
      refreshTokenExpiration,
      isAuthenticated,
      userData,
    }),
  ),
  on(AUTH_DATA_ACTIONS.RefreshTokensSuccess, (state, { token, refreshToken, tokenExpiration, refreshTokenExpiration }) => ({
    ...state,
    token,
    refreshToken,
    tokenExpiration,
    refreshTokenExpiration,
  })),
);

export const authDataReducer = (state: AuthDataState | undefined, action: Action): AuthDataState => authDataActionReducer(state, action);
