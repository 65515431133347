@if (mainUserRole$ | async; as mainUserRole) {
  <div class="flex flex-col w-[var(--sidebar-width)] justify-between">
    <!-- Top menu container -->
    <div class="flex flex-col gap-1 py-4 max-h-[724px] overflow-y-auto no-scrollbar">
      <div class="flex flex-col gap-1 pr-3 pb-4">
        <div class="py-2 px-4 opacity-50 font-bold text-xs">Meniu Principal</div>
        <!-- Menu items start -->
        <ul>
          @for (item of menuItems; track $index) {
            @if (!(mainUserRole.isUser && item.hiddenFromOperator)) {
              <li>
                <app-menu-item [menuIcon]="item.icon" [text]="item.text" [submenuItems]="item.subMenuItems" [url]="item.url" />
              </li>
            }
          }
        </ul>
      </div>
    </div>

    <!-- Bottom menu container -->
    <div class="flex flex-col min-h-44">
      <!-- Divider -->
      <div class="flex w-[232px] h-[1px] self-center bg-sidebar-divider-gradient"></div>
      <div class="flex flex-col flex-1 gap-1 pt-4 pr-3 pb-3">
        <div class="py-2 px-4 opacity-50 text-xs font-bold">Sistem</div>
        <ul>
          @for (item of bottomMenuItems; track $index) {
            <li>
              @if (!mainUserRole.isUser || !item.hiddenFromOperator) {
                <app-menu-item [menuIcon]="item.icon" [submenuItems]="item.subMenuItems" [text]="item.text" [url]="item.url" />
              }
            </li>
          }
        </ul>
      </div>
    </div>
  </div>
}
