import { HttpEvent, HttpHandlerFn, HttpHeaders, HttpInterceptorFn, HttpParams, HttpRequest } from '@angular/common/http';
import { inject } from '@angular/core';
import { AuthService } from './auth.service';
import { Store } from '@ngrx/store';
import { BehaviorSubject, catchError, filter, finalize, Observable, switchMap, take, throwError, withLatestFrom } from 'rxjs';
import { LoaderService } from './loader.service';
import { AUTH_DATA_SELECTORS } from '../../../state/authentication/auth.selectors';
import { StorageKeysEnum } from '../../../feature/shared/enums/storage-keys.enum';
import { ENVIRONMENT } from '../../../../../environments/environment';
import { Tokens } from '../../../feature/shared/models/auth-data.model';

export const authInterceptor: HttpInterceptorFn = (request, next) => {
  const authService = inject(AuthService);
  const store = inject(Store);
  const loadingService = inject(LoaderService);

  let totalRequests = 0;

  const isRefreshingSubject = new BehaviorSubject<boolean>(false);
  const refreshTokenSubject = new BehaviorSubject<string | null>(null);

  totalRequests++;
  loadingService.setLoading(true);

  if (request.url.includes('/auth') || request.url.includes('/ocr')) {
    return next(request).pipe(
      finalize(() => stopLoading(totalRequests, loadingService)),
    );
  }

  if (request.url.startsWith(ENVIRONMENT.configuration.uris.agencies.api)) {
    const headers = request.headers.set('x-api-key', ENVIRONMENT.configuration.uris.agencies.apiKey);
    return handleClone(request, headers, request.params, next);
  }

  return store.select(AUTH_DATA_SELECTORS.selectToken).pipe(
    withLatestFrom(
      store.select(AUTH_DATA_SELECTORS.selectRefreshToken),
      store.select(AUTH_DATA_SELECTORS.selectTokenExpiration),
    ),
    take(1),
    switchMap(([token, refreshToken, tokenExpiration]) => {
      if (token && tokenExpiration && !authService.isTokenExpired(tokenExpiration)) {
        return next(addToken(request, token)).pipe(
          finalize(() => stopLoading(totalRequests, loadingService)),
        );
      }

      if (isRefreshingSubject.value) {
        return refreshTokenSubject.pipe(
          filter((refreshingToken) => refreshingToken !== null),
          take(1),
          switchMap((refreshingToken) => next(addToken(request, refreshingToken))),
          finalize(() => stopLoading(totalRequests, loadingService)),
        );
      }

      const storedRefreshToken = localStorage.getItem(StorageKeysEnum.REFRESH_TOKEN);
      const availableRefreshToken = storedRefreshToken ?? refreshToken;

      if (availableRefreshToken) {
        isRefreshingSubject.next(true);
        refreshTokenSubject.next(null);

        return handleTokenExpired(
          request,
          next,
          authService,
          availableRefreshToken,
          isRefreshingSubject,
          refreshTokenSubject,
          totalRequests,
          loadingService,
        );
      }
      return throwError(() => new Error('No refresh token available'));
    }),
  );
};

function addToken(request: HttpRequest<unknown>, token: string): HttpRequest<unknown> {
  return request.clone({
    setHeaders: {
      authorization: `Bearer ${token}`,
    },
  });
}

function handleTokenExpired(
  request: HttpRequest<unknown>,
  next: HttpHandlerFn,
  authService: AuthService,
  refreshToken: string,
  isRefreshing: BehaviorSubject<boolean>,
  refreshTokenSubject: BehaviorSubject<string | null>,
  totalRequests: number,
  loadingService: LoaderService,
): Observable<HttpEvent<unknown>> {
  return authService.refreshToken(refreshToken).pipe(
    switchMap((tokens: Tokens) => {
      refreshTokenSubject.next(tokens.refreshToken);
      return next(addToken(request, tokens.token));
    }),
    catchError(() => {
      refreshTokenSubject.next(null);
      return throwError(() => new Error('Failed to refresh token'));
    }),
    finalize(() => {
      isRefreshing.next(false);
      stopLoading(totalRequests, loadingService);
    }),
  );
}

function stopLoading(totalRequests: number, loadingService: LoaderService): void {
  const remainingRequests = totalRequests - 1;
  if (remainingRequests === 0) {
    loadingService.setLoading(false);
  }
}

const handleClone = (
  req: HttpRequest<unknown>,
  headers: HttpHeaders,
  params: HttpParams,
  next: HttpHandlerFn,
): Observable<HttpEvent<unknown>> => {
  const reqClone = req.clone({ headers, params });
  return next(reqClone);
};
