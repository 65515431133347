<!-- Root container -->
<div class="flex flex-col h-screen bg-gradient-light bg-fixed text-white">
  <!-- Header -->
  <div class="flex flex-row h-[var(--main-header-height)]">
    <!-- Brand selector -->
    <div
      class="flex fle-row w-[var(--sidebar-width)] bg-brand-selector shadow-inner border-b border-[#FFFFFF1A] px-3 justify-between items-center"
    >
      <div class="flex flex-row items-center justify-center gap-3">
        <img class="drop-shadow-md rounded-md size-10" src="assets/images/stanleybet-logo.png" alt="Logo" />
        <div class="font-bold drop-shadow">Stanleybet</div>
      </div>
    </div>
    <!-- Rest of header -->
    <div class="flex flex-row flex-1 px-content justify-between items-center">
      <div>
        <span class="text-lg text-[#FEF2F2] px-4 font-bold">REGISTRU DASHBOARD</span>
      </div>
      <div class="flex pr-4 flex-row items-center">
        @if (userDetails$ | async; as userDetails) {
          <div class="flex flex-row cursor-pointer items-center gap-4" (click)="toggleUserMenu()">
            <app-profile-picture [firstName]="''" [lastName]="''" [width]="'10'" [height]="'10'" />
            <div class="flex flex-col min-w-28">
              <div class="font-bold text-sm">{{ userDetails.email }}</div>
              @if (currentUserRole$ | async; as currentUserRole) {
                <div class="text-xs font-normal opacity-60">{{ currentUserRole }}</div>
              }
            </div>
            <app-fa-icon
              class="font-black drop-shadow transition-transform duration-300 ease-in-out"
              iconName="chevron-down"
              [ngClass]="{ 'rotate-180': showUserMenu$ | async }"
            />
          </div>
          <!-- User menu -->
          @if (showUserMenu$ | async) {
            <div
              @userMenuAnimation
              appClickOutside
              (clickOutside)="closeUserMenu()"
              class="absolute right-3 z-50 bg-gradient-light top-14 mt-2 w-56 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none"
            >
              <div class="w-full gap-1" role="none">
                <a
                  [routerLink]="NAVIGATION_PATH.CHANGE_PASSWORD"
                  type="button"
                  class="block w-full px-4 py-2 text-left text-sm text-white rounded-md transition-all hover:bg-active-menu-gradient"
                >
                  <app-fa-icon iconName="gear-complex"></app-fa-icon>
                  Schimbă Parola
                </a>
                <button
                  class="block w-full px-4 py-2 text-left text-sm text-white rounded-md transition-all hover:bg-active-menu-gradient"
                  (click)="logout()"
                >
                  <app-fa-icon iconName="arrow-left"></app-fa-icon>
                  Deconectare
                </button>
              </div>
            </div>
          }
        }
      </div>
    </div>
  </div>

  <!-- Main container -->
  <div class="flex flex-row flex-1 overflow-hidden">
    <!-- Sidebar -->
    <app-sidebar class="flex" />

    <!-- Main -->
    <div class="flex flex-col flex-1 bg-slate-100 text-slate-700 overflow-y-auto no-scrollbar">
      <ng-content />
    </div>
  </div>
</div>
