import { inject, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { ToastrService } from 'ngx-toastr';
import { catchError, map, of } from 'rxjs';
import { StorageKeysEnum } from '../../feature/shared/enums/storage-keys.enum';
import { Tokens } from '../../feature/shared/models/auth-data.model';
import { AUTH_DATA_ACTIONS } from './auth.actions';
import { AuthService } from '../../core/auth/services/auth.service';
import { jwtDecode } from 'jwt-decode';
import { TokenDecodeModel } from '../../feature/shared/models/token-decode.model';
import { CurrentUserModel } from '../../feature/shared/models/user.model';
import { NAVIGATION_PATH } from '../../feature/shared/constants/navigation-paths.const';

// Set access token lifecycle to 5 minutes
export const ACCESS_TOKEN_LIFECYCLE = 5;
// Set access token lifecycle to 24 hours
export const REFRESH_TOKEN_LIFECYCLE = 24;

@Injectable()
export class AuthDataEffects {
  private readonly authService = inject(AuthService);
  private readonly actions$ = inject(Actions);
  private readonly toastr = inject(ToastrService);
  private readonly router = inject(Router);

  private readonly setUserLogin$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AUTH_DATA_ACTIONS.Login),
      map((data) => {
        const tokens = {
          token: data.token,
          refreshToken: data.refreshToken,
        };

        this.saveTokensToStorage(tokens);

        return AUTH_DATA_ACTIONS.LoginSuccess({
          isAuthenticated: data.isAuthenticated,
          token: data.token,
          refreshToken: data.refreshToken,
          tokenExpiration: this.getTokenExpiration(),
          refreshTokenExpiration: this.getRefreshTokenExpiration(),
          userData: data.userData,
        });
      }),
      catchError(() => of(AUTH_DATA_ACTIONS.LoginFailed())),
    ),
  );

  private readonly setUserLogout$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AUTH_DATA_ACTIONS.Logout),
      map(() => {
        this.clearAuthData();
        this.router.navigate([NAVIGATION_PATH.LOGIN]);
        this.toastr.info('V-ați delogat cu success');

        return AUTH_DATA_ACTIONS.LogoutSuccess({
          isAuthenticated: false,
          token: undefined,
          refreshToken: undefined,
          tokenExpiration: undefined,
          refreshTokenExpiration: undefined,
          userData: undefined,
        });
      }),
      catchError(() => of(AUTH_DATA_ACTIONS.LogoutFailed())),
    ),
  );

  private readonly refreshTokens$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AUTH_DATA_ACTIONS.RefreshTokens),
      map((data) => {
        const tokens = {
          token: data.token,
          refreshToken: data.refreshToken,
        };

        this.saveTokensToStorage(tokens);

        return AUTH_DATA_ACTIONS.RefreshTokensSuccess({
          token: data.token,
          refreshToken: data.refreshToken,
          tokenExpiration: this.getTokenExpiration(),
          refreshTokenExpiration: this.getRefreshTokenExpiration(),
        });
      }),
      catchError(() => of(AUTH_DATA_ACTIONS.RefreshTokensFailed())),
    ),
  );

  private readonly handleTokenExpiration$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AUTH_DATA_ACTIONS.RefreshTokensFailed),
      map(() => {
        this.toastr.error('Sesiunea dumneavoastră a expirat. Vă rugăm să vă autentificați din nou.');
        return AUTH_DATA_ACTIONS.Logout();
      }),
    ),
  );

  private readonly hydrateTokens$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AUTH_DATA_ACTIONS.HydrateTokens),
      map(() => {
        const token = localStorage.getItem(StorageKeysEnum.TOKEN);
        const refreshToken = localStorage.getItem(StorageKeysEnum.REFRESH_TOKEN);
        const tokenExpiration = localStorage.getItem(StorageKeysEnum.TOKEN_EXPIRATION);
        const refreshTokenExpiration = localStorage.getItem(StorageKeysEnum.REFRESH_TOKEN_EXPIRATION);

        if (token && refreshToken && tokenExpiration && refreshTokenExpiration) {
          const decodedUserData: TokenDecodeModel = jwtDecode(token);
          const modifiedUserData: CurrentUserModel = {
            ...decodedUserData,
            roles: this.authService.getUserRoles(decodedUserData.role),
          };
          return AUTH_DATA_ACTIONS.HydrateTokensSuccess({
            token,
            refreshToken,
            tokenExpiration,
            refreshTokenExpiration,
            isAuthenticated: true,
            userData: modifiedUserData,
          });
        }

        return AUTH_DATA_ACTIONS.LogoutSuccess({
          isAuthenticated: false,
          token: undefined,
          refreshToken: undefined,
          tokenExpiration: undefined,
          refreshTokenExpiration: undefined,
          userData: undefined,
        });
      }),
      catchError(() => of(AUTH_DATA_ACTIONS.HydrateTokensFailed())),
    ),
  );

  private clearAuthData(): void {
    localStorage.removeItem(StorageKeysEnum.TOKEN);
    localStorage.removeItem(StorageKeysEnum.REFRESH_TOKEN);
    localStorage.removeItem(StorageKeysEnum.TOKEN_EXPIRATION);
    localStorage.removeItem(StorageKeysEnum.REFRESH_TOKEN_EXPIRATION);
  }

  private saveTokensToStorage(tokens: Tokens): void {
    if (tokens.token) {
      localStorage.setItem(StorageKeysEnum.TOKEN, tokens.token);
    }

    if (tokens.refreshToken) {
      localStorage.setItem(StorageKeysEnum.REFRESH_TOKEN, tokens.refreshToken);
    }
  }

  private getTokenExpiration(): string {
    const accessTokenExpiration = new Date();

    accessTokenExpiration.setUTCMinutes(accessTokenExpiration.getUTCMinutes() + ACCESS_TOKEN_LIFECYCLE);

    localStorage.setItem(StorageKeysEnum.TOKEN_EXPIRATION, JSON.stringify(accessTokenExpiration));

    return JSON.stringify(accessTokenExpiration);
  }

  private getRefreshTokenExpiration(): string {
    const refreshTokenExpiration = new Date();

    refreshTokenExpiration.setUTCHours(refreshTokenExpiration.getUTCHours() + REFRESH_TOKEN_LIFECYCLE);

    localStorage.setItem(StorageKeysEnum.REFRESH_TOKEN_EXPIRATION, JSON.stringify(refreshTokenExpiration));

    return JSON.stringify(refreshTokenExpiration);
  }
}
