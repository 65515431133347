import { AsyncPipe, NgClass } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { SubMenuItemComponent } from '../sub-menu-item/sub-menu-item.component';
import { RouterLink, RouterLinkActive } from '@angular/router';
import { BehaviorSubject, Observable } from 'rxjs';
import { Memoized } from '@acetech-development/utilities/core';
import { SubMenuItem } from './submenu-item.model';
import { AppFaIconComponent } from '../fa-icon/fa-icon.component';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'app-menu-item',
  standalone: true,
  imports: [
    // 3rd
    AsyncPipe,
    NgClass,
    RouterLink,
    RouterLinkActive,
    // Project
    AppFaIconComponent,
    SubMenuItemComponent,
  ],
  templateUrl: './menu-item.component.html',
  styleUrl: './menu-item.component.css',
})
export class MenuItemComponent {
  @Input({ required: true }) public menuIcon!: string;
  @Input({ required: true }) public text!: string;
  @Input() public submenuItems: SubMenuItem[] | undefined;
  @Input() public url: string | undefined;

  private readonly isActiveSubject = new BehaviorSubject<boolean>(false);

  public onRouterLinkActive(isActive: boolean): void {
    this.isActiveSubject.next(isActive);
  }

  public toggleMenu(): void {
    if (this.submenuItems) {
      const currentState = this.isActiveSubject.getValue();
      this.isActiveSubject.next(!currentState);
    }
  }

  @Memoized
  public get isActive$(): Observable<boolean> {
    return this.isActiveSubject.asObservable();
  }
}
