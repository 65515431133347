import { Routes } from '@angular/router';
import { authGuard } from './presentation/core/auth/services/auth.guard';
import { loggedInGuard } from './presentation/core/auth/services/logged-in.guard';
import { NAVIGATION_PATH } from './presentation/feature/shared/constants/navigation-paths.const'; /* eslint-disable id-length */

/* eslint-disable id-length */
export const ROUTES: Routes = [
  {
    path: '',
    redirectTo: NAVIGATION_PATH.AGENCIES,
    pathMatch: 'full',
  },
  {
    path: NAVIGATION_PATH.AGENCIES,
    loadComponent: () => import('./presentation/feature/agencies/agencies.component').then((c) => c.AgenciesComponent),
    children: [
      {
        path: `${NAVIGATION_PATH.DETAILS}/:agencyCode`,
        loadComponent: () =>
          import('./presentation/feature/agencies/agency-details/agency-details.component').then(
            (component) => component.AgencyDetailsComponent,
          ),
      },
    ],
    canActivate: [authGuard],
  },
  {
    path: NAVIGATION_PATH.USERS,
    loadComponent: () => import('./presentation/feature/users/users.component').then((c) => c.UsersComponent),
    children: [
      {
        path: `${NAVIGATION_PATH.DETAILS}/:id`,
        loadComponent: () =>
          import('./presentation/feature/users/user-details/user-details.component').then((component) => component.UserDetailsComponent),
      },
      {
        path: `${NAVIGATION_PATH.CREATE}`,
        loadComponent: () =>
          import('./presentation/feature/users/user-details/user-details.component').then((component) => component.UserDetailsComponent),
      },
    ],
    canActivate: [authGuard],
  },
  {
    path: NAVIGATION_PATH.BET_REGISTER,
    loadComponent: () => import('./presentation/feature/bet-register/bet-register.component').then((c) => c.BetRegisterComponent),
    children: [
      {
        path: `${NAVIGATION_PATH.DETAILS}/:id`,
        loadComponent: () =>
          import('./presentation/feature/bet-register/bet-register-details/bet-register-details.component').then(
            (component) => component.BetRegisterDetailsComponent,
          ),
      },
    ],
    canActivate: [authGuard],
  },
  {
    path: NAVIGATION_PATH.DOCUMENT,
    loadComponent: () => import('./presentation/feature/documents/documents.component').then((c) => c.DocumentsComponent),
    canActivate: [authGuard],
  },
  {
    path: NAVIGATION_PATH.CHANGE_PASSWORD,
    loadComponent: () =>
      import('./presentation/core/auth/change-password/change-password.component').then((c) => c.ChangePasswordComponent),
    canActivate: [authGuard],
  },
  {
    path: NAVIGATION_PATH.TRANSACTIONS,
    loadComponent: () => import('./presentation/feature/transactions/transactions.component').then((c) => c.TransactionsComponent),
    canActivate: [authGuard],
    children: [
      {
        path: `${NAVIGATION_PATH.DETAILS}/:id`,
        loadComponent: () =>
          import('./presentation/feature/transactions/transaction-details/transaction-details.component').then(
            (component) => component.TransactionDetailsComponent,
          ),
      },
    ],
  },
  {
    path: NAVIGATION_PATH.LOGIN,
    loadComponent: () => import('./presentation/core/auth/login/login.component').then((c) => c.LoginComponent),
    canActivate: [loggedInGuard],
  },
  {
    path: `${NAVIGATION_PATH.RESET_PASSWORD}/:token`,
    loadComponent: () => import('./presentation/core/auth/reset-password/reset-password.component').then((c) => c.ResetPasswordComponent),
    canActivate: [loggedInGuard],
  },
];
